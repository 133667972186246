@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  background-color: #eeeeeed4;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-slideshow-container .nav:first-of-type {
  margin-left: 20px;
}

.react-slideshow-container .nav:last-of-type {
  margin-right: 20px;
}

.max-container {
  @apply max-w-[95%] md:max-w-[1400px] mx-auto;
}

button {
  background-color: #276897;
  color: white;
  border: 1px solid transparent;
}

button:hover {
  color: black;
  border: 1px solid black;
  background: none;
}
